<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">DNA</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">prcd PRA</div>
              <v-tooltip bottom v-if="dog.testing">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="
                      $refs.dnaForm.openForm(
                        dog.testing,
                        'prcd_pra',
                        'prcd PRA'
                      )
                    "
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>
                    <div v-if="dog.testing">
                      {{ dog.testing.dna_prcd_pra_result }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>
                    <div v-if="dog.testing">
                      {{ dog.testing.dna_prcd_pra_comment }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.dna_prcd_pra_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.dna_prcd_pra_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">vWD</div>
              <v-tooltip bottom v-if="dog.testing">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.dnaForm.openForm(dog.testing, 'vwd', 'vWD')"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.dna_vwd_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.dna_vwd_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.dna_vwd_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.dna_vwd_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">EIC</div>
              <v-tooltip bottom v-if="dog.testing">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.dnaForm.openForm(dog.testing, 'eic', 'EIC')"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.dna_eic_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.dna_eic_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.dna_eic_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.dna_eic_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">DM</div>
              <v-tooltip bottom v-if="dog.testing">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.dnaForm.openForm(dog.testing, 'dm', 'DM')"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.dna_dm_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.dna_dm_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.dna_dm_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.dna_dm_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">IC</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.dnaForm.openForm(dog.testing, 'ic', 'IC')"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.dna_ic_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.dna_ic_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.dna_ic_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.dna_ic_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">PRA, RCD4</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="
                      $refs.dnaForm.openForm(dog.testing, 'pra', 'PRA, RCD4')
                    "
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.dna_pra_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.dna_pra_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.dna_pra_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.dna_pra_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">NEWS</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.dnaForm.openForm(dog.testing, 'news', 'News')"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.dna_news_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.dna_news_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.dna_news_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.dna_news_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Orthopedic</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">Hips</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.hipsForm.openForm(dog.testing)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.orthopedic_hips_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.orthopedic_hips_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.orthopedia_hips_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.orthopedia_hips_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">Elbows</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.elbowsForm.openForm(dog.testing)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.orthopedic_elbows_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.orthopedic_elbow_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.orthopedic_elbow_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.orthopedic_elbow_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">Patellae</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.patellaeForm.openForm(dog.testing)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.orthopedic_patellas_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.orthopedic_patellas_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.orthopedic_patellas_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="
                        dog.testing.asset_urls.orthopedic_patellas_document
                      "
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Optometry</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-3"
              ><div class="flex-grow-1">BVA Eyes</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.optometryForm.openForm(dog.testing)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip></v-card-subtitle
            >
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Result</th>
                  <td>{{ dog.testing.optometry_bva_eyes_result }}</td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>{{ dog.testing.optometry_bva_eyes_comment }}</td>
                </tr>
                <tr>
                  <th>Doc. Upload</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.optometry_bva_eyes_document"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.optometry_bva_eyes_document"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Other</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.otherForm.openForm(dog.testing)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Notes</th>
                  <td>{{ dog.testing.notes }}</td>
                </tr>
                <tr>
                  <th>Health Certificate</th>
                  <td>
                    <v-btn
                      v-if="dog.testing.health_certificate"
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="dog.testing.asset_urls.health_certificate"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <OptometryForm ref="optometryForm" />
      <OtherForm ref="otherForm" />
      <ElbowsForm ref="elbowsForm" />
      <HipsForm ref="hipsForm" />
      <PatellaeForm ref="patellaeForm" />
      <DNAForm ref="dnaForm" />
    </v-container>
  </div>
</template>

<script>
import OtherForm from "../components/testing/OtherForm";

import OptometryForm from "../components/testing/OptometryForm";
import ElbowsForm from "../components/testing/ElbowsForm";
import HipsForm from "../components/testing/HipsForm";
import PatellaeForm from "../components/testing/PatellaeForm";
import DNAForm from "../components/testing/DNAForm";

export default {
  components: {
    OptometryForm,
    OtherForm,
    ElbowsForm,
    HipsForm,
    PatellaeForm,
    DNAForm,
  },

  data() {
    return {};
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },
  },
};
</script>
